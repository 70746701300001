import * as React from "react";
import Helmet from "react-helmet";

import Navbar from "./Navbar";
import Footer from "./Footer";

import "../assets/styles/index.sass";

class TemplateWrapper extends React.Component<any> {
  render() {
    return (
      <div className="container mx-auto">
        <Helmet
          defaultTitle="DKX Media"
          titleTemplate="DKX Media - %s"
          htmlAttributes={{ lang: "de" }}
          meta={[
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1.0, minimum-scale=1.0"
            }
          ]}
        />
        <Navbar />
        <div>{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}

export default TemplateWrapper;
