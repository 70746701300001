import * as React from "react";
import { Link } from "gatsby";
import { bubble as Menu } from "react-burger-menu";
import logo from "../assets/img/logo.svg";

class Navbar extends React.Component<{}, { burgerMenuOpen: boolean }> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      burgerMenuOpen: false
    };
  }

  closeMenu() {
    this.setState({ burgerMenuOpen: false });
  }

  render() {
    return (
      <nav>
        <div>
          <div>
            <Link to="/" title="homepage" style={{ border: "none" }}>
              <img src={logo} alt="DKXMedia Logo" />
            </Link>
          </div>
          <div className="hidden md:block">
            <Link to="/" className="active" title="homepage">
              Startseite
            </Link>
            &nbsp;|&nbsp;
            <Link to="/software" title="software">
              Software
            </Link>
            &nbsp;|&nbsp;
            <Link to="/design" title="design">
              Design
            </Link>
            &nbsp;|&nbsp;
            <Link to="/marketing" title="marketing">
              Marketing
            </Link>
            &nbsp;|&nbsp;
            <Link to="/kontakt" title="contact">
              Kontakt
            </Link>
          </div>
          <div className="md:hidden">
            <Menu right isOpen={this.state.burgerMenuOpen}>
              <Link to="/" className="menu-item" onClick={() => this.closeMenu()}>
                STARTSEITE
              </Link>
              <Link to="/de/leistungen" className="menu-item" onClick={() => this.closeMenu()}>
                LEISTUNGEN
              </Link>
              <Link to="/de/kontakt" className="menu-item" onClick={() => this.closeMenu()}>
                KONTAKT
              </Link>
              <Link className="menu-item" onClick={() => this.closeMenu()} to="/de/impressum">
                IMPRESSUM
              </Link>

              <Link className="menu-item" onClick={() => this.closeMenu()} to="/de/datenschutz">
                DATENSCHUTZ
              </Link>
            </Menu>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
