import * as React from "react";
import { Link } from "gatsby";
import logo from "../assets/img/logo.svg";

class Navbar extends React.Component<any> {
  render() {
    return (
      <footer>
        <div>
          <div>
            <p>Software</p>
            <p>
              <Link to="/" title="homepage">
                Startseite
              </Link>
            </p>
            <p>
              <Link to="/" title="homepage">
                Startseite
              </Link>
            </p>
            <p>
              <Link to="/" title="homepage">
                Startseite
              </Link>
            </p>
          </div>
          <div>
            <p>Software</p>
            <p>
              <Link to="/" title="homepage">
                Startseite
              </Link>
            </p>
            <p>
              <Link to="/" title="homepage">
                Startseite
              </Link>
            </p>
            <p>
              <Link to="/" title="homepage">
                Startseite
              </Link>
            </p>
          </div>
          <div>
            <p>Software</p>
            <p>
              <Link to="/" title="homepage">
                Startseite
              </Link>
            </p>
            <p>
              <Link to="/" title="homepage">
                Startseite
              </Link>
            </p>
            <p>
              <Link to="/" title="homepage">
                Startseite
              </Link>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Navbar;
